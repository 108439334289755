<template>
<v-container>
    <v-row>
        <v-col
            cols="12"
        >
                <v-card>
                    <v-card-text>
                        <h3>LOGIN</h3>
                    </v-card-text>
                    <v-card-text>
                        <v-form
                            ref="form"
                        >
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="form.email" :counter="50"
                                    label="E-mail Address" clearable
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                    v-model="form.password" :counter="30"
                                    label="Password" type="password" clearable
                                    ></v-text-field>
                                </v-col>

                                <v-col md="12">
                                    <v-btn
                                    color="success" class="mr-4"
                                    @click="login">
                                    Go &raquo;
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

  export default {
    name: 'SigninCard',
    data: () => ({
        form:{
            email:null,
            password:null,
        },
    }),
    methods: {
        login () {
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_AUTHURL+'/signin', this.form,
            {
                headers: {'Device-Referrer-Id': process.env.VUE_APP_DEVICEID}
            })
            .then((response) => {
                this.$store.commit("authToken", response.data.token);
                this.$emit('success', true)
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error. Try again Later!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
    },
  }
</script>
