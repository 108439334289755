<template>
<v-container>
    <v-row>
        <v-col cols="12"
        >
            <v-card elevation="1">
                <v-card-text>
                    <h3>CREATE ACCOUNT</h3>
                </v-card-text>
                <v-card-text>
                    <v-form
                    ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                            v-model="form.username"
                            :counter="30" :rules="nameRules"
                            label="Username" required clearable
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                            v-model="form.email" :counter="50" clearable
                            :rules="emailRules" label="E-mail Address" required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                            v-model="form.password"
                            :counter="30" :rules="passwordRules" clearable
                            label="Password" type="password" required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-checkbox
                            v-model="form.policy"
                            :rules="[v => !!v || 'You must agree to continue!']" required
                            >
                            <template v-slot:label>
                                <div>
                                I agree to the
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                    <a
                                        @click="tosModal = !tosModal"
                                        v-on="on"
                                    >
                                        Terms &amp; Privacy Policy
                                    </a>
                                    </template>
                                    Click to Read
                                </v-tooltip>
                                </div>
                            </template>
                            </v-checkbox>
                            <tos v-if="tosModal"/>
                        </v-col>

                        <v-col md="12">
                            <v-btn
                            :disabled="!valid" color="success"
                            class="mr-4" @click="signup"
                            >
                            Go &raquo;
                            </v-btn>
                        </v-col>
                </v-row>
                </v-form>
                </v-card-text>
            </v-card>
            <tos v-if="tosModal"/>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import Tos from './Tos.vue';

  export default {
  components: { Tos },
    name: 'SignupComponent',
    data: () => ({
        tosModal:false,
        form:{
            username:null,
            email:null,
            password:null,
            policy:null,
            shop:true,
        },
        valid: true,
        nameRules: [
            v => !!v || 'Fullname is required',
            v => (v && v.length <= 50) || 'Fullname must be less than 50 characters',
        ],
        passwordRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 8) || 'Password must not be less than 8 characters',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        checkbox: false,
    }),

    methods: {
        signup () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_AUTHURL+'/signup', this.form,
            {
                headers: {'Device-Referrer-Id': process.env.VUE_APP_DEVICEID}
            })
            .then((response) => {
                this.$store.commit("authToken", response.data.token);
                this.$emit('success', true)
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error. Try again Later!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
    },
  }
</script>
